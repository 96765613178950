import React, { useState } from "react";
import "./App.css"; // Import the CSS file
import logo from "./assets/logo.png"; // relative path to image

const App = () => {
  const [isHovered, setIsHovered] = useState(false); // State to manage hover

  return (
    <div className="container">
      <a
        href="mailto:hello@bioinformative.com" // Replace with your desired URL
        className="floating-text"
        target="_blank" // Opens the link in a new tab
        rel="noopener noreferrer" // Security measure for the new tab
      >
        hello@bioinformative.com
      </a>
      <img
        src={logo} // Replace with your image URL
        alt="Logo"
        className={`image ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => setIsHovered(true)} // Set hover state to true
        onMouseLeave={() => setIsHovered(false)} // Set hover state to false
      />
    </div>
  );
};

export default App;
